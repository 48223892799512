<template>
	<div id="wrap">
    <main-header></main-header>
        <div id="container">
		<div class="contents" >
			<div class="innWrap">
				<div class="subMainCnt" style="text-align:center;">
					<!-- <h2 class="pageTit">온라인 청약서 작성</h2> -->
					<div class="eastiRqDone" style="margin-top:240px;">
                        <img src="../../../assets/images/img_Error.jpg" style="margin-bottom:30px; width:400px; height:300px; align:center;"><br>
                        <p style="line-height: 1.8;">등록되지 않은 페이지입니다.<br>
                        <p style="line-height: 1.8;">궁금한 점이 있으시면 고객센터로 문의바랍니다.<br>
                        유선전화(국번없이)100, 휴대폰(지역번호)+100</p>
					</div>                        
					<div class="bbsBtnWrap" style="margin:60px 0">
						<button type="button" class="btn-lg btn-purple" @click="$router.push('/goMain000')">홈 화면으로 가기</button>
					</div>
				</div>
			</div>
		</div>
	</div>
    <main-footer></main-footer>
	</div>
</template>
<script>
	import { gigaofficeService } from 'Api'
    import Header from './Header.vue'
    import Footer from './Footer.vue'
    import $ from 'jquery'

    export default {
        name: 'pageNotFound',
        components : {
            'main-header' : Header,
            'main-footer' : Footer
        },
        data () {
            return {
				
            }
        },
		computed: {
		},
        created: function () {
        },
        mounted: function () {
        },
        destroyed: function () {
        },
        methods: {
        }
    }
</script>

<style>
@import '../../../assets/css/common.css'
</style>
